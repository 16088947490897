exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-collections-js": () => import("./../../../src/pages/collections.js" /* webpackChunkName: "component---src-pages-collections-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-exhibities-js": () => import("./../../../src/pages/exhibities.js" /* webpackChunkName: "component---src-pages-exhibities-js" */),
  "component---src-pages-historic-js": () => import("./../../../src/pages/historic.js" /* webpackChunkName: "component---src-pages-historic-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infotur-js": () => import("./../../../src/pages/infotur.js" /* webpackChunkName: "component---src-pages-infotur-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-structure-js": () => import("./../../../src/pages/structure.js" /* webpackChunkName: "component---src-pages-structure-js" */),
  "component---src-templates-blog-news-jsx": () => import("./../../../src/templates/blog-news.jsx" /* webpackChunkName: "component---src-templates-blog-news-jsx" */),
  "component---src-templates-blog-post-news-jsx": () => import("./../../../src/templates/blog-post-news.jsx" /* webpackChunkName: "component---src-templates-blog-post-news-jsx" */),
  "component---src-templates-blog-post-services-jsx": () => import("./../../../src/templates/blog-post-services.jsx" /* webpackChunkName: "component---src-templates-blog-post-services-jsx" */),
  "component---src-templates-blog-post-tourism-jsx": () => import("./../../../src/templates/blog-post-tourism.jsx" /* webpackChunkName: "component---src-templates-blog-post-tourism-jsx" */)
}

